.background {
    overflow-x: hidden;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.centering-div {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
}

.login-attachment {
    width: 100%;
    background-color: white;
    border-radius: 13px;

    transition: 200ms;

    -webkit-box-shadow: 0 0 4px #00000010;
    -moz-box-shadow: 0 0 4px #00000010;
    box-shadow: 0 0 14px #00000015;
}
.login-attachment {
    padding: 20px 6px;
    min-height: 340px;
}

.login-attachment > h2 {
    text-align: center;
    color: #1990FF !important;
    font-weight: bold;

}

.login-attachment > h4 {
    text-align: center;
    font-weight: bold;
    color: #616161 !important;

}

.asan-login-button-card {
    padding: 30px 20px;
    box-shadow: 0 0 12px #00000015;
    border-radius: 13px;
    width: 80%;
    margin: 40px auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 3fr;
    transition: 200ms;
}

.asan-login-button-card:hover {
    transition: 200ms;
    cursor: pointer;
    box-shadow: 0 0 18px #00000020;
}

.asan-login-button-card > div > img {
    margin-top: 10px;
    display: block;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
}



/*Media queries begin here*/


/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .centering-div {
        width: 80%;
    }

    .asan-login-button-card {
        grid-template-columns: 1fr;
        width: 90%;
    }

    .asan-login-button-card h4, h5 {
        text-align: center;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .centering-div {
        width: 90%;
    }
}