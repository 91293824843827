@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

.ant-form-item {
    margin-bottom: 5px !important;
}

.error-color {
    color: orangered;
}

.log-out, .log-out > h4 {
    transition: 200ms;
    color: white;
}

.log-out:hover, .log-out > h4:hover {
    color: orangered;
    transition: 200ms;
}

.landing-font {
    font-family: "Exo 2", serif;
    font-weight: 900;
    color: rgb(13,12,43);
}

.rubic-font {
    font-family: "Rubik", serif;
    font-weight: 500;
}

.my-button, .my-button {
    background-color: rgb(54,111,233);
    width: 130px;
    color: white; border-radius: 30px;
    padding: 16px 20px;
    cursor: pointer;
    transition: 200ms;
}

.my-button:hover {
    background-color: rgba(54,111,233, 0.75);
    transition: 200ms;
}


.ant-layout-sider::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.ant-table table {
    width: 150% !important;
}

.ant-table-thead {
    height: 50px !important;
}